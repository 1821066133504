<script setup lang="ts">
import { RouterView } from 'vue-router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import PhoneFooter from '@/components/PhoneFooter.vue'
import BtcConverter from '@/components/BtcConverter.vue'
// 部署测试环境时，需要注释掉+222

const theme = {
  hashed: false,
  token: {
    fontFamily: 'inherit',
    fontSize: 'inherit'
  }
}
</script>

<template>
  <a-config-provider :theme="theme">
    <a-layout class="layout">
      <Header v-if="!$route.meta.hideNav" />
      <RouterView class="container" />
      <Footer class="footer-pc" />
      <PhoneFooter class="footer-phone" />
      <btc-converter />
    </a-layout>
  </a-config-provider>
</template>

<style lang="scss" scoped>
.layout {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  font-family: Inter, sans-serif;
  background: #030303;
  background-size: 100% 100%;
  font-size: 16px;
  line-height: 1.5;

  > nav.header + footer {
    opacity: 0;
  }
}

.container {
  max-width: var(--container-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  padding-top: 60px;
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);

  @include sm() {
    padding-top: 20px;
  }
}

.footer-pc {
  display: flex;
}
.footer-phone {
  display: none;
}

@media (max-width: 768px) {
  .footer-pc {
    display: none;
  }
  .footer-phone {
    display: flex;
  }
}
</style>

<style lang="scss">
@import 'ant-design-vue/dist/reset.css';
@import 'styles/variables';
@import '@exsat/common/styles/fonts';
@import 'styles/card';
@import 'styles/badge';
@import 'styles/form';
@import 'styles/button';
@import 'styles/table';
@import 'styles/toast';
@import 'styles/utils';

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-family-satoshi);
  font-weight: 700;
  line-height: 1.5;
}

h1 {
  font-size: var(--heading-1);
  line-height: 1.2;
}

h2 {
  font-size: var(--heading-2);
  line-height: 1.25;
}

h3 {
  font-size: var(--heading-3);
}

h4 {
  font-size: var(--heading-4);
}

h5 {
  font-size: var(--font-md);
}

a {
  color: currentColor;
  transition: 0.3s;
  text-decoration: underline;

  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.8;
  }

  &:hover,
  &:focus,
  &:active {
    color: currentColor;
    opacity: 0.75;
    text-decoration: underline;
  }

  &.white {
    text-decoration: none;
  }

  &.muted {
    color: var(--text-gray);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: var(--text-white);
    }
  }

  &.gray {
    text-decoration: underline;
    color: var(--text-gray);

    &:hover,
    &:focus,
    &:active {
      color: var(--text-white);
    }
  }

  &.orange {
    color: var(--orange);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: rgba(255, 153, 0, 0.6);
    }
  }
}

[hidden] {
  display: none !important;
  visibility: hidden;
}

svg {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.ant-tooltip {
  color: var(--bg-secondary-color);
  --antd-arrow-background-color: var(--bg-secondary-color);
  font-size: var(--font-sm);
  line-height: 1.5;

  .ant-tooltip-inner {
    background-color: var(--bg-secondary-color);
    padding: var(--spacing-lg);
  }
}

.tooltip-title {
  text-decoration: 2px dotted underline;
  text-decoration-color: #666;
}
</style>
